import styled from "styled-components"

const white_color = "#fff"
const primary_color = "green"
const primary_color_hover = "lightgreen"
const heading_color = "#060F1E"

const NavbarWrapper = styled.nav`
  .container {
    background: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (min-width: 1220px) and (max-width: 1440px) {
      max-width: 1170px !important;
    }
    @media only screen and (max-width: 1440px) {
      padding: 0 30px;
    }
  }

  .logo {
    max-width: 107px;
    width: 100%;
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding: 20px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
  }

  a {
    padding: 10px 5px;
    min-width: 100px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      background: skyblue;
      width: 20%;
      border-radius: 5px;
      height: 2px;
      left: 5%;
    }
    // padding: 0px;
    // text-decoration: none;
    // color: #000;
    // margin: 1px;
    // border-radius: 5px;
    // min-width: 150px;
    // text-align: center;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    padding-right: 20px;
  }
  @media only screen and (max-width: 1360px) {
    padding-right: calc(75px + 20px);
  }
  @media only screen and (max-width: 1260px) {
    padding-right: calc(75px + 30px);
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }

  a {
    font-size: 16px;
    font-weight: 600;
    margin-right: 46px;
    color: #111;
    transition: all 0.25s ease;
    @media only screen and (max-width: 1440px) {
      margin-right: 40px;
    }
    @media only screen and (max-width: 1360px) {
      margin-right: 30px;
    }

    &:hover {
      color: ${primary_color};
    }
  }
`

export const Button = styled.button`
  border: 0;
  padding: 0;
  min-width: 150px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: ${white_color};
  background-color: ${primary_color};
  transition: all 0.3s ease;
  @media only screen and (max-width: 1440px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 14px;
  }

  &:hover {
    background-color: ${primary_color_hover};
  }

  img {
    margin-left: 11px;
    @media only screen and (max-width: 1440px) {
      margin-left: 10px;
    }
  }

  &:hover,
  &:focus {
    content: 0;
    box-shadow: none;
  }
`

export default NavbarWrapper
