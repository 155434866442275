import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Image from "../../reusecore/elements/Image"
import Container from "../../common/components/UI/Container"
import NavbarWrapper, { MenuWrapper, MobileMenu, Button } from "./navbar.style"

import logoImage from "../../images/logo.png"
// import heartImage from "common/src/assets/image/charity/heart-red.png"

const Navbar = props => {
  const { showOnlyHome } = props
  return (
    <NavbarWrapper className="navbar">
      <Container fullWidth={true}>
        <Link className="logo" to="/">
          <Image src={logoImage} alt="Next Landing" width={50} />
        </Link>
        <MenuWrapper>
          {showOnlyHome && <a href="/">Home</a>}
          {!showOnlyHome && (
            <>
              <AnchorLink className="smooth_scroll" href="#about" offset={81}>
                About Us
              </AnchorLink>
              <AnchorLink className="smooth_scroll" href="#branch" offset={81}>
                Companies
              </AnchorLink>
              <AnchorLink className="smooth_scroll" href="#blog" offset={81}>
                Updates
              </AnchorLink>
            </>
          )}
          <a href="/gallery">Gallery</a>
          <a href="/contact-us">
            <Button>
              <span className="text"> Contact US</span>
            </Button>
          </a>
        </MenuWrapper>
      </Container>
      <MobileMenu>
        {showOnlyHome && <a href="/">Home</a>}
        {!showOnlyHome && (
          <>
            <AnchorLink className="smooth_scroll" href="#about" offset={81}>
              About Us
            </AnchorLink>
            <AnchorLink className="smooth_scroll" href="#branch" offset={81}>
              Companies
            </AnchorLink>
            <AnchorLink className="smooth_scroll" href="#blog" offset={81}>
              Updates
            </AnchorLink>
          </>
        )}
        <a href="/gallery">Gallery</a>
        <a href="/contact-us">
          <span className="text"> Contact US</span>
        </a>
      </MobileMenu>
    </NavbarWrapper>
  )
}

export default Navbar
