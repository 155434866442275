/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Navbar from "./Navbar"
import Footer from "./Footer"
import "./layout.css"

const Div2 = styled.div`
  background-color: #fafbfe;
`

const Layout = ({ children, showOnlyHome }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar showOnlyHome={showOnlyHome} />
      <div className="main">{children}</div>
      <Div2>
        <Footer />
      </Div2>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
